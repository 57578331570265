@use "./variables" as *;

.footer {
  border-top: 1px solid $grey15;
  &-main {
    justify-content: space-between;
    padding-block: 66px;
    border-bottom: 1px solid $grey15;
  }

  &-nav {
    gap: 30px;
  }

  &-link:hover {
    color: $white;
  }

  .copyright {
    justify-content: space-between;
    padding-block: 50px;
  }

  .infos {
    gap: 30px;
  }

  .info {
    gap: 10px;
  }
}

@media (max-width: 1250px) {
  .footer {
    &-main {
      flex-direction: column;
      gap: 25px;
    }
  }
}

@media (max-width: 950px) {
  .footer {
    &-main {
      padding-block: 30px;
    }
    .copyright {
      flex-direction: column;
      padding-block: 30px;
      gap: 20px;
    }
  }
}

@media (max-width: 575px) {
  .footer {
    .logo img {
      width: 50px;
      height: 50px;
    }

    .footer-nav {
      gap: 10px;
      flex-wrap: wrap;
      justify-content: center;
    }
    .socials {
      img {
        width: 40px;
        height: 40px;
      }
    }
    .infos {
      flex-direction: column;
      gap: 10px;
      img {
        height: 20px;
      }
    }
  }
}
