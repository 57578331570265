@use "./variables" as *;

.career-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.career-card {
  padding: 100px 50px;
  border: 0.5px solid $grey15;

  h2 {
    padding-bottom: 50px;
    border-bottom: 1px solid $grey15;
    margin-bottom: 50px;
  }
}

.jobs-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.job-card {
  border: 0.5px solid $grey15;
  padding: 50px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  h3 {
    margin-block: 30px 20px;
  }

  .apply-link {
    padding: 18px;
    text-align: center;
    border: 1px solid $grey15;
    border-radius: 8px;
    width: 100%;
    margin-top: 30px;

    &:hover {
      background-color: transparent;
    }
  }
}

.career-subtitle {
  div {
    border: 0.5px solid $grey15;
  }
  h3 {
    padding: 50px;
  }
}

@media (max-width: 1000px) {
  .career-card {
    padding: 50px 25px;

    h2 {
      padding-bottom: 25px;
      border-bottom: 1px solid $grey15;
      margin-bottom: 25px;
    }
  }
  .career-subtitle h3 {
    padding: 25px;
  }
  .job-card {
    padding: 25px;
  }
}

@media (max-width: 800px) {
  .jobs-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 650px) {
  .career-grid {
    grid-template-columns: 1fr;
  }
}
