@use "./variables" as *;

.header-info {
  padding-block: 120px;
  text-align: center;
  background-position: center center;
  background-size: cover;
  margin-block: 50px;

  h2 {
    margin-bottom: 14px;
  }

  p {
    max-width: 1000px;
    margin: auto;
  }
}

.header-info-services {
  background-image: url("../assets/images/services.png");
}
.header-info-whychoose {
  background-image: url("../assets/images/whyChoose.png");
}
.header-info-about {
  background-image: url("../assets/images/about.png");
}
.header-info-faqs {
  background-image: url("../assets/images/faqs.png");
}
.header-info-process {
  background-image: url("../assets/images/process.png");
}
.header-info-contact {
  background-image: url("../assets/images/about.png");
}
.header-info-work {
  background-image: url("../assets/images/workImage.png");
}

@media (max-width: 850px) {
  .header-info {
    margin-block: 15px;

    p {
      padding-inline: 10px;
    }
  }
}
