@use "./variables" as *;

.header {
  justify-content: space-between;
  padding-block: 20px;

  .nav {
    gap: 30px;

    &-link:hover {
      color: $white;
    }

    .active {
      padding: 14px 28px;
      border-radius: 8px;
    }
  }
}

.toggle-button {
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border: none;
  display: none;
  border-radius: 5px;
}

.toggle-container {
  display: none;
}

.toggle-icon {
  width: 25px;
}

@media (max-width: 850px) {
  .header {
    padding-block: 10px;
  }
  .toggle-button {
    display: block;
    z-index: 1000;
  }
  .toggle-container {
    display: block;
    z-index: 1000;
  }
  .header-button-container {
    display: none;
  }

  .nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    z-index: 900;
    font-size: 25px;
    align-items: center;
    background-color: $grey15;
    transform: translateY(-100%);
  }

  .nav-open {
    transform: translateY(0%);
  }
}
