@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

// Absolute Colors
$black: #000000;
$white: #ffffff;

// Green Shades
$green50: #9eff00;
$green60: #b1ff33;
$green70: #c5ff66;
$green80: #d8ff99;
$green90: #ecffcc;
$green95: #f5ffe5;
$green97: #f9fff0;
$green99: #fdfffa;

// Grey Shades
$grey10: #1a1a1a;
$grey15: #262626;
$grey20: #333333;
$grey30: #4c4c4d;
$grey35: #59595a;
$grey40: #656567;
$grey60: #98989a;
$grey90: #e6e6e6;

// Font Family
$ffBarlow: "Barlow", sans-serif;

// Font Sizes
$fs1000: clamp(5rem, 2.2656rem + 8.75vw, 9.375rem); // 150px - 80px
$fs900: clamp(2.125rem, 0.7969rem + 4.25vw, 4.25rem); // 68px - 34px
$fs800: clamp(1.75rem, 0.9688rem + 2.5vw, 3rem); // 48px - 28px
$fs700: clamp(1.5rem, 0.9531rem + 1.75vw, 2.375rem); // 38px - 24px
$fs600: clamp(1.125rem, 0.7344rem + 1.25vw, 1.75rem); // 28px - 18px
$fs500: clamp(1.25rem, 1.0156rem + 0.75vw, 1.625rem); // 26px - 20px
$fs400: clamp(1rem, 0.7656rem + 0.75vw, 1.375rem); // 22px - 16px
$fs300: clamp(0.875rem, 0.7188rem + 0.5vw, 1.125rem); // 18px - 14px
