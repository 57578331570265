@use "./variables" as *;

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: inherit;
}
body,
html {
  -webkit-overflow-scrolling: touch;
}

html {
  overflow-x: hidden;
}

body {
  min-height: 100vh;
  font-family: $ffBarlow;
  font-size: $fs300;
  color: $grey90;
  overflow-x: hidden;
  background-color: $grey10;
}
html {
  scroll-behavior: smooth;
}
input,
button,
textarea {
  font: inherit;
}
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

// General Classes
.flex {
  display: flex;
  align-items: center;
}
.container {
  max-width: 1600px;
  padding-inline: 15px;
  margin: auto;
}
.logo {
  gap: 10px;

  img {
    height: 60px;
  }
}
.socials {
  gap: 20px;
  padding: 14px;
  border-radius: 12px;
  border: 1px solid $grey15;

  div {
    gap: 14px;

    img {
      height: 64px;
    }
  }
}
.button-green {
  padding: 14px 22px;
  border: 1px solid $green50;
  border-radius: 8px;

  &:hover {
    background-color: $grey15;
    color: $green50;
  }
}
.button-gray {
  padding: 14px 22px;
  border: 1px solid $grey20;
  border-radius: 8px;

  &:hover {
    background-color: $green50;
    color: $grey15;
  }
}
.pointer {
  cursor: pointer;
}
.transition {
  transition: all 0.3s;
}
.hidden {
  display: none;
}

// Typography
.extraBold {
  font-weight: 800;
}
.bold {
  font-weight: 700;
}
.semiBold {
  font-weight: 600;
}
.medium {
  font-weight: 500;
}
.italic {
  font-style: italic;
}
.uppercase {
  text-transform: uppercase;
}

// Font Family
.ffBarlow {
  font-family: $ffBarlow;
}

// Font Sizes
.fs300 {
  font-size: $fs300;
}
.fs400 {
  font-size: $fs400;
}
.fs500 {
  font-size: $fs500;
}
.fs600 {
  font-size: $fs600;
}
.fs700 {
  font-size: $fs700;
}
.fs800 {
  font-size: $fs800;
}
.fs900 {
  font-size: $fs900;
}
.fs1000 {
  font-size: $fs1000;
}

// Absolute Colors
.black {
  color: $black;
}
.white {
  color: $white;
}

// Green Shades
.green50 {
  color: $green50;
}
.green60 {
  color: $green60;
}
.green70 {
  color: $green70;
}
.green80 {
  color: $green80;
}
.green90 {
  color: $green90;
}
.green95 {
  color: $green95;
}
.green97 {
  color: $green97;
}
.green99 {
  color: $green99;
}

// Grey Shades
.grey10 {
  color: $grey10;
}
.grey15 {
  color: $grey15;
}
.grey30 {
  color: $grey30;
}
.grey30 {
  color: $grey30;
}
.grey35 {
  color: $grey35;
}
.grey40 {
  color: $grey40;
}
.grey60 {
  color: $grey60;
}
.grey90 {
  color: $grey90;
}

// Background Colors
.bgGreen50 {
  background-color: $green50;
}
.bgGrey15 {
  background-color: $grey15;
}
.bgGrey20 {
  background-color: $grey20;
}
