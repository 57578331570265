@use "./variables" as *;

.work-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.work-card {
  padding: 30px 50px 100px 50px;
  border: 0.5px solid $grey15;

  img {
    width: 100%;
  }

  .preview {
    margin-block: 60px 30px;
  }

  .flex {
    justify-content: space-between;
    margin-block: 15px 30px;
  }
  .link-preview {
    padding: 10px 15px;
    border-radius: 8px;
  }
}

@media (max-width: 1000px) {
  .work-card {
    padding: 25px;
  }
}

@media (max-width: 650px) {
  .work-grid {
    grid-template-columns: 1fr;
  }
}
