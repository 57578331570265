@use "./variables" as *;

.services-card {
  padding: 50px;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;
  border: 0.5px solid $grey15;
  width: 100%;

  img {
    width: 88px;
    height: 88px;
  }
}

.services-heading {
  padding: 50px;
  border: 0.5px solid $grey15;
}

.services-container {
  align-items: stretch;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media (max-width: 1200px) {
  .services-card {
    padding: 25px;

    img {
      width: 50px;
      height: 50px;
    }
  }
  .services-heading {
    padding: 25px;
  }
}

@media (max-width: 800px) {
  .services-container {
    grid-template-columns: 1fr 1fr;
  }
}
