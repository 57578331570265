@use "./variables" as *;

.contact-form {
  flex-direction: column;
  max-width: 1060px;
  margin: auto;
  padding-inline: 80px;
  gap: 40px;

  .shortInput-container {
    gap: 40px;
    width: 100%;
  }

  .shortInput,
  .textarea,
  .checkboxes,
  .sliderInput {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    background-color: #242424;
    padding: 24px 40px;
    border-radius: 8px;
    width: 100%;

    input,
    textarea {
      padding-bottom: 10px;
      background-color: transparent;
      outline: none;
      border: none;
      width: 100%;
      border-bottom: 1px solid $grey20;
      resize: none;
    }
  }

  .checkboxes-inputs {
    flex-wrap: wrap;
    width: 100%;
    gap: 24px;

    div {
      width: calc(50% - 12px);

      input {
        display: none;
      }
      input:checked + .checkbox::after {
        content: "✔";
        color: $green50;
        font-size: 14px;
      }

      .checkbox {
        width: 28px;
        height: 28px;
        border: 2px solid $grey20;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.5rem;
      }
    }
  }

  button {
    padding: 18px 44px;
  }
}

.range-slider {
  position: relative;
  width: 100%;
  height: 56px;

  input {
    border-bottom: none !important;
  }
}

.range-slider input[type="range"] {
  position: absolute;
  width: 100%;
  top: 0;
  height: 56px;
  pointer-events: none;
  background: transparent;
  -webkit-appearance: none;
  appearance: none;
}

.range-slider input[type="range"]::-webkit-slider-thumb {
  pointer-events: auto;
  -webkit-appearance: none;
  appearance: none;
  height: 20px;
  width: 20px;
  background-color: $green50;
  border: 4px solid #3a3a3a;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  top: 4px;
  z-index: 3;
}

.range-slider input[type="range"]:first-of-type::-webkit-slider-thumb {
  margin-left: -4px;
}

.range-slider input[type="range"]:last-of-type::-webkit-slider-thumb {
  margin-right: -4px;
}

.range-slider input[type="range"]::-moz-range-thumb {
  pointer-events: auto;
  height: 20px;
  width: 20px;
  background-color: $green50;
  border: 4px solid #3a3a3a;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  top: 4px;
  z-index: 3;
}

.range-slider input[type="range"]:first-of-type::-moz-range-thumb {
  margin-left: -4px;
}

.range-slider input[type="range"]:last-of-type::-moz-range-thumb {
  margin-right: -4px;
}

.range-slider .track {
  position: absolute;
  height: 4px;
  width: 100%;
  background: #3a3a3a;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.range-slider .highlight {
  position: absolute;
  height: 4px;
  background: $green50;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.range-slider .value-label {
  position: absolute;
  top: 20px;
  font-size: 14px;
  top: 65%;
  left: 50%;
  transform: translateX(-50%);
}

.contact-info {
  padding-block: 50px;
  justify-content: center;
  gap: 30px;
  margin-block: 50px 30px;
  flex-wrap: wrap;

  div {
    gap: 14px;
    padding: 18px 25px;
    border-radius: 8px;
  }
}

.contact-footer {
  justify-content: space-between;
  max-width: 900px;
  margin-top: 50px;
  padding-block: 30px;
  .opening-days {
    h3:last-child {
      padding: 12px 24px;
      border-radius: 6px;
      margin-left: 14px;
    }
  }
}

@media (max-width: 850px) {
  .contact-info {
    padding-block: 20px;
    margin-block: 20px;
  }
}

@media (max-width: 750px) {
  .contact-form {
    padding: 0;
    gap: 20px;

    .shortInput-container {
      gap: 20px;
    }

    .shortInput,
    .checkboxes,
    .sliderInput,
    .textarea {
      padding: 20px;
    }
  }

  .contact-footer {
    flex-direction: column;
    margin-top: 20px;
    padding-block: 20px 25px;
    gap: 20px;

    .socials img {
      width: 40px;
      height: 40px;
    }
  }
}

@media (max-width: 450px) {
  .contact-form {
    .button-green {
      padding: 15px 40px;
    }
  }
  .contact-info {
    gap: 15px;
    div {
      width: 100%;
      justify-content: center;
      padding-block: 15px;
    }
  }
}
