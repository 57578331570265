@use "./variables" as *;

.characteristics {
  margin-block: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: stretch;

  &-card {
    padding: 80px;
    flex-direction: column;
    gap: 40px;
    align-items: flex-start;

    div {
      gap: 20px;

      img {
        height: 88px;
      }
    }
  }

  .card-border-top {
    border-top: 1px solid $grey20;
  }
  .card-border-right {
    border-right: 1px solid $grey20;
  }
  .card-border-bottom {
    border-bottom: 1px solid $grey20;
  }
  .card-border-left {
    border-left: 1px solid $grey20;
  }
}

@media (max-width: 1000px) {
  .characteristics-card {
    padding: 50px 25px;
    gap: 20px;
  }
}

@media (max-width: 650px) {
  .characteristics {
    grid-template-columns: 1fr;

    &-card {
      border: 0.5px solid $grey20;
    }
  }
}
