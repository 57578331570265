@use "./variables" as *;

.companies {
  padding: 40px 15px 10px;
  position: relative;

  &-flex {
    justify-content: space-between;
  }

  &-num {
    position: absolute;
    top: -31px;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px 35px;
    border: 1px solid $grey15;
    background-color: $grey10;
    border-radius: 100px;
  }
}

@media (max-width: 775px) {
  .companies-num {
    padding: 15px;
    text-align: center;
    width: 70%;
  }
}

.carousel-container {
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
}

.carousel-track {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.carousel-item {
  min-width: 25%; /* Ensures 4 items are visible */
  flex-shrink: 0;
}
