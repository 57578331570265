@use "./variables" as *;

.hero {
  flex-direction: column;
  text-align: center;
  padding-block: 115px 275px;
  gap: 50px;
  background-image: url("../assets/images/hero.png");
  background-position: center bottom;
  background-size: contain;
  background-repeat: no-repeat;

  h1 {
    max-width: 750px;
  }

  &-card {
    padding: 24px 40px;
    border: 1px solid $grey15;
    border-radius: 12px;

    span {
      padding: 12px 14px;
      border-radius: 8px;
    }
  }

  &-buttons {
    justify-content: center;
    gap: 13px;
  }
}
@media (max-width: 775px) {
  .hero {
    padding-block: 50px;

    &-card {
      padding: 15px 20px;
    }

    span {
      padding: 0;
      border: none;
      background-color: transparent;
      color: $grey60;
    }
  }
}
