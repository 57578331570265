.cta {
  padding-block: 90px;
  margin-block: 50px;
  flex-direction: column;
  text-align: center;
  gap: 50px;
  background-image: url("../assets/images/ctaBg.png");
  background-size: cover;
  background-position: center center;

  img {
    height: 80px;
  }

  div {
    max-width: 900px;
  }

  h2 {
    margin-bottom: 14px;
  }
}

@media (max-width: 850px) {
  .cta {
    margin-block: 15px;
    gap: 25px;

    a {
      padding: 10px 20px;
    }

    img {
      height: 60px;
    }

    p,
    h2 {
      padding-inline: 10px;
    }
  }
}
