@use "./variables" as *;

.testimonials {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-block: 10px;
  align-items: stretch;

  &-card {
    padding: 100px 80px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    .description {
      margin-block: 24px 40px;
    }

    .author-info {
      padding: 21.5px 20px;
      border-radius: 8px;
      border: 1px solid $grey15;
      width: 100%;
      justify-content: space-between;
      background-color: #1c1c1c;

      img {
        height: 60px;
        width: 60px;
        object-fit: cover;
        border-radius: 8px;
        margin-right: 18px;
      }

      p {
        margin-top: 2px;
      }

      .link {
        padding: 18px 20px;
      }
    }
  }

  .border-top {
    border-top: 1px solid $grey20;
  }
  .border-right {
    border-right: 1px solid $grey20;
  }
  .border-bottom {
    border-bottom: 1px solid $grey20;
  }
  .border-left,
  &-card:last-child {
    border-left: 1px solid $grey20;
  }
}

@media (max-width: 1250px) {
  .testimonials-card {
    padding: 50px;
  }
}

@media (max-width: 1080px) {
  .testimonials {
    grid-template-columns: 1fr;

    &-card {
      border: 0.5px solid $grey20;
    }
  }
}

@media (max-width: 750px) {
  .testimonials {
    &-card {
      padding: 25px;
      flex-wrap: wrap;

      .author-info a {
        display: none;
      }
    }
  }
}
