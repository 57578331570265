@use "./variables" as *;

.faqs {
  display: grid;
  grid-template-columns: 1fr 1fr;

  &-card {
    padding: 30px 50px;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid $grey20;

    .question {
      justify-content: space-between;
      width: 100%;
      gap: 50px;

      &-question {
        gap: 30px;
        width: 100%;
      }
    }

    .question-num {
      width: 80px !important;
      height: 80px !important;
      justify-content: center;
      align-items: center;
      background: rgb(36, 36, 36);
      background: linear-gradient(
        0deg,
        rgba(36, 36, 36, 1) 0%,
        rgba(36, 36, 36, 0) 100%
      );
      border-radius: 12px;
    }

    .question-title {
      width: 100%;
    }
  }

  &-card:last-child,
  &-card:nth-last-child(2) {
    border-bottom: 0;
  }

  .answer {
    margin-top: 20px;
  }

  .border-right {
    border-right: 1px solid $grey20;
  }
}

@media (max-width: 850px) {
  .faqs {
    grid-template-columns: 1fr;

    &-card {
      border: none !important;
      border-bottom: 1px solid $grey20 !important;
      padding: 20px 10px;
    }

    .question {
      gap: 15px;
    }

    .question-question {
      gap: 10px;
    }
  }
}
