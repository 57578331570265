@use "./variables" as *;

.main-services {
  margin-block: 10px;
  align-items: stretch;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  &-card {
    padding: 50px;
    flex-direction: column;
    justify-content: space-between;

    img {
      height: 88px;
    }

    h3 {
      margin-block: 40px 20px;
    }

    .card-button {
      margin-top: 80px;
      padding: 18px;
      width: 100%;
      text-align: center;

      &:hover {
        background-color: $green50;
        color: $grey15;
      }
    }
  }

  &-card-border {
    border-right: 1px solid $grey20;
    border-left: 1px solid $grey20;
  }
}

@media (max-width: 1000px) {
  .main-services {
    grid-template-columns: 1fr 1fr;

    &-card {
      padding: 25px;
      border: 0.5px solid $grey20;

      img {
        width: 50px;
        height: 50px;
      }

      .card-button {
        margin-top: 50px;
      }
    }
  }
}

@media (max-width: 650px) {
  .main-services {
    grid-template-columns: 1fr;
  }
}
