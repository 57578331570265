@use "./variables" as *;

.process-card {
  padding: 100px 50px;
  border: 0.5px solid $grey15;
  flex-direction: column;
  gap: 50px;
  align-items: flex-start;

  h1 {
    margin-right: 25px;
  }
}

.process-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 80px;
}

@media (max-width: 1000px) {
  .process-card {
    padding: 50px 25px;
    gap: 25px;
  }
}

@media (max-width: 650px) {
  .process-container {
    grid-template-columns: 1fr;
  }
}
