@use "./variables" as *;

.about-container {
  padding: 100px 150px;
  gap: 100px;

  img {
    width: 50%;
  }

  h2 {
    margin-bottom: 20px;
  }
}

.about-footer {
  padding: 80px;
  .about-top {
    gap: 40px;
    margin-bottom: 50px;

    h3 {
      margin-bottom: 20px;
    }
  }

  .footer-bottom {
    padding: 24px 40px;
    border: 1px solid $grey15;
    justify-content: space-between;
    gap: 15px;

    p {
      padding: 14px 20px;
    }
  }
}

@media (max-width: 1000px) {
  .about-container {
    flex-direction: column;
    text-align: center;
    padding: 20px 15px;
    gap: 50px;

    img {
      width: 80%;
      max-width: 600px;
    }
  }
}
