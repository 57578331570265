.pagesInfo {
  padding: 70px 50px 50px 50px;

  p {
    padding-block: 14px 50px;
    max-width: 1200px;
  }

  button {
    padding: 12px 14px;
    border-radius: 8px;
    border: none;
  }
}

@media (max-width: 850px) {
  .pagesInfo {
    padding: 25px 0px 40px 0px;

    p {
      padding-block: 14px 20px;
    }
  }
}
